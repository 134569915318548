const actions = {
  SET_MI_STATE: 'clientes/SET_MI_STATE',
  SET_STATE: 'clientes/SET_STATE',
  SET_GRUPOS_CLIENTES: 'clientes/SET_GRUPOS_CLIENTES',
  LOAD_MIS_CLIENTES: 'clientes/LOAD_MIS_CLIENTES',
  LOAD_CLIENTES: 'clientes/LOAD_CLIENTES',
  LOAD_GRUPOS_CLIENTES: 'clientes/LOAD_GRUPOS_CLIENTES',
  EDITAR_CLIENTE: 'clientes/EDITAR_CLIENTE',
  CREAR_CLIENTE: 'clientes/CREAR_CLIENTE',
  ELIMINAR_CLIENTE: 'clientes/ELIMINAR_CLIENTE',
}

export default actions
