import axios from 'axios';
import { domain } from '../helpers/domains';

class Crud {

  constructor(url = "", hasToken = true) {
    const token = localStorage.getItem('token');
    this.globalUrl =  `${domain}/${url}`
    this.authorizaton = hasToken ? { Authorization: `Bearer ${token}` } : {};
  }

  getConfig(header = {}) {
    return {
      headers: {
        ...this.authorizaton,
        ...header
      }
    }
  }

  async sendRequest(info = {}, key = 'get') {
    try {

      const { data = {}, header = {}, url: urlRequest = "",  } = info;
      const request = await axios[key](
        `${this.globalUrl}${urlRequest}`,
        key === 'get' ? this.getConfig(header) : data,
        key === 'get' ? undefined : this.getConfig(header),
      );

      if (request.status === 200) {
        return request.data
      }

      return [];

    } catch({ response }) {
      return response;
    }
  }

  get(info) {
    return this.sendRequest(info, 'get');
  }

  post(info) {
    return this.sendRequest(info, 'post');
  }

  put(info) {
    return this.sendRequest(info, 'put');
  }

  delete(info) {
    return this.sendRequest(info, 'delete');
  }

}

export default Crud;
