import axios from 'axios'
// import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const obtenerNominasPendientes = async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  
  const nominasPendientes = await axios.get(`${global.domain}/nominas-pendientes/`,config);
  return nominasPendientes;
}
