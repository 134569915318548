const actions = {
  SET_STATE: 'finiquitos/SET_STATE',
  LOAD_FINIQUITOS: 'finiquitos/LOAD_FINIQUITOS',
  EDITAR_FINIQUITO: 'finiquitos/EDITAR_FINIQUITO',
  CREAR_FINIQUITO: 'finiquitos/CREAR_FINIQUITO',
  ELIMINAR_FINIQUITO: 'finiquitos/ELIMINAR_FINIQUITO',
  TOGGLE_EXPANDED_ROW_KEYS: 'finiquitos/TOGGLE_EXPANDED_ROW_KEYS',
  SET_EMPLOYEE_DETAILS: 'finiquitos/SET_EMPLOYEE_DETAILS'
}
  
export default actions