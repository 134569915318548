import axios from 'axios'

// eslint-disable-next-line import/prefer-default-export
export const obtenerPeriodoDeNominas = async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const periododenomina = await axios.get(`${global.domain}/periodos-de-nomina/`,config);
  return periododenomina;
}

export const editarPeriodoDeNomina = (periodo) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const periododenomina = await axios.put(`${global.domain}/periodos-de-nomina/`, periodo,config);
  return periododenomina;
}

export const crearPeriodoDeNomina = (periodo) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const periododenomina = await axios.post(`${global.domain}/periodos-de-nomina/`, periodo,config);
  return periododenomina;
}

export const eliminarPeriodoDeNomina = (periodo) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const periododenomina = await axios.delete(`${global.domain}/periodos-de-nomina/${periodo.id}`,config);
  return periododenomina;
}

