/* eslint-disable no-debugger */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { obtenerTickets, editarTicket, crearTicket, eliminarTicket } from 'services/tickets'
import actions from './actions'

export function* LOAD_TICKETS() {
  yield put({
    type: 'tickets/SET_STATE',
    payload: {
      loading: true,
    },
  })

  try {
    const response = yield call(obtenerTickets)
    if (response) {
      const { data: entities } = response
      yield put({
        type: 'tickets/SET_STATE',
        payload: {
          loading: false,
          entities
        },
      })
    }
  } catch (error) {
    const token = localStorage.getItem('token');
    if(token){
      notification.warning({
        message: "Hubo un error al obtener los tickets",
        description: "Verifica que estés conectado a internet",
      });
    }else{
      console.error(error)
    } ;
  }
}

export function* EDITAR_TICKET(ticket) {

  try {

    const response = yield call(editarTicket(ticket.payload));

    if (response) {
      yield put({type: 'tickets/LOAD_TICKETS'})
    }
  } catch (error) {
    notification.warning({
      message: "Hubo un error al editar el elemento",
      description: "Verifica que estés conectado a internet",
    });

  }
}

export function* CREAR_TICKET(ticket) {

  try {

    const response = yield call(crearTicket(ticket.payload));

    if (response) {
      yield put({type: 'tickets/LOAD_TICKETS'})
    }
  } catch (error) {
    notification.warning({
      message: "Hubo un error al crear el elemento",
      description: "Verifica que estés conectado a internet",
    });

  }
}

export function* ELIMINAR_TICKET(ticket) {

  try {

    const response = yield call(eliminarTicket(ticket.payload));

    if (response) {
      yield put({type: 'tickets/LOAD_TICKETS'})
    }
  } catch (error) {
    notification.warning({
      message: "Hubo un error al eliminar el elemento",
      description: "Verifica que estés conectado a internet",
    });

  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_TICKETS, LOAD_TICKETS),
    takeEvery(actions.EDITAR_TICKET, EDITAR_TICKET),
    takeEvery(actions.CREAR_TICKET, CREAR_TICKET),
    takeEvery(actions.ELIMINAR_TICKET, ELIMINAR_TICKET),
    LOAD_TICKETS(), // run once on app load to check user auth
  ])
}
