export const Alta = 'Alta de Empleados'
export const Baja = 'Baja de Empleados'
export const Aclaratoria = 'Carta Aclaratoria'
export const Patronal = 'Carta Patronal'
export const Laboral = 'Constancia Laboral'
export const Dispersion = 'Cuentas para Dispersión'
export const Fonacot = 'Fonacot'
export const Guarderia = 'Guarderia'
export const Incapacidad = 'Incapacidad'
export const Infonavit = 'Infonavit'
export const Migracion = 'Migración'
export const ModDatos = 'Modificacion de Datos'
export const ModSalario = 'Modificacion de Salario'
export const ST7 = 'ST7'
export const Transfer = 'Transferencia de Semanas'
export const Visa = 'Visa'
export const Renovacion = 'Renovación'
export const ModNomina = 'Modificación de Nómina'
