import lodash from 'lodash'
import actions from './actions'

const initialState = {
  entities: [],
  index: {},
  data: {},
  loading: false,
}

export default function nominasReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case actions.SET_STATE_TRABAJADA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            ...action.payload,
          },
        },
      }
    case 'nominas/SET_STATE_TRABAJADA_WS':
      // console.log('llegaron cambios', action.payload)
      
      if (lodash.get(state, `data[${action.payload.id}].id`, false)) {
        console.log('Ya existia, se actualizará', action.payload)
        const nominaNueva = {
          ...state.data[action.payload.id],
          ...action.payload,
        };

        // console.log('nominaNueva->',nominaNueva);

        const data = {
          ...state.data,
          [action.payload.id]:nominaNueva,
        };

        // console.log('data->',data);
        
        const newState = {
          ...state,
          data, 
        };
        return newState;
      }
      // console.log('hubo cambios pero no entro ')
      return state

    default:
      return state
  }
}
