import axios from 'axios'

// eslint-disable-next-line import/prefer-default-export
export const obtenerPeticiones = async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };

  const peticiones = await axios.get(`${global.domain}/peticiones/`, config);
  return peticiones;
}

export const editarSolicitud = (solicitud) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };

  const peticiones = await axios.put(`${global.domain}/peticiones/`, solicitud, config);
  return peticiones;
}

export const crearSolicitud = (solicitud) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };

  const peticiones = await axios.post(`${global.domain}/peticiones/`, solicitud, config);
  return peticiones;
}

export const eliminarSolicitud = (solicitud) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  
  const peticiones = await axios.delete(`${global.domain}/peticiones/${solicitud.id}`, config);
  return peticiones;
}

