import { all, takeEvery, put } from 'redux-saga/effects'
import { notification/* , message */ } from 'antd'
import lodash from 'lodash'
import { getTotalPercepciones } from 'services/total-percepciones'
import actions from './actions'

export function* LOAD_TOTAL_PERCEPCIONES() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  try {
    const response = yield getTotalPercepciones();

    if (response) {
      const { data: entities } = response
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
          entities,
          index: lodash.keyBy(entities, 'id'),
        },
      })
    }
  } catch (error) {
    const token = localStorage.getItem('token');
    if(token){
      notification.warning({
        message: 'Hubo un error al obtener los nominas',
        description: 'Verifica que estés conectado a internet',
      })
    }else{
      console.error(error)
    } ;
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_TOTAL_PERCEPCIONES, LOAD_TOTAL_PERCEPCIONES),
  ])
}
