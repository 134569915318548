import actions from './actions'

const initialState = {
  entities: [],
  index: {},
  loading: false
};

export default function peticionesReducer(state = initialState, action) {
  let index;
  switch (action.type) {
    case actions.SET_STATE:
      if (action.payload.entities) {
        index = action.payload.entities.reduce((ac, ent) => {
          return { ...ac, [ent.id]: ent };
        }, {});
      }

      return {
        ...state,
        ...action.payload,
        index
      }
    default:
      return state
  }
}
