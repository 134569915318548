import axios from 'axios'

// eslint-disable-next-line import/prefer-default-export
export const obtenerUsuarios = async () => {

  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const usuarios = await axios.get(`${global.domain}/usuarios/`,config);
  return usuarios;
}

export const editarUsuario = (usuario) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const usuarios = await axios.put(`${global.domain}/usuarios/`, usuario,config);
  return usuarios;
}

export const crearUsuario = (usuario) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const usuarios = await axios.post(`${global.domain}/usuarios/`, usuario,config);
  return usuarios;
}

export const eliminarUsuario = (usuario) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const usuarios = await axios.delete(`${global.domain}/usuarios/${usuario.id}`,config);
  return usuarios;
}
