import axios from 'axios'

// eslint-disable-next-line import/prefer-default-export
export const obtenerBancos = async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const bancos = await axios.get(`${global.domain}/bancos/`,config);
  return bancos;
}

export const editarBanco = (banco) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const bancos = await axios.put(`${global.domain}/bancos/`, banco, config);
  return bancos;
}

export const crearBanco = (banco) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const bancos = await axios.post(`${global.domain}/bancos/`, banco,config);
  return bancos;
}

export const eliminarBanco = (banco) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const bancos = await axios.delete(`${global.domain}/bancos/${banco.id}`,config);
  return bancos;
}

