import axios from 'axios'

// eslint-disable-next-line import/prefer-default-export
export const obtenerEmpresas = async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const empresas = await axios.get(`${global.domain}/empresas/`,config);
  return empresas;
}

export const editarEmpresa = (empresa) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const empresas = await axios.put(`${global.domain}/empresas/`, empresa,config);
  return empresas;
}

export const crearEmpresa = (empresa) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const empresas = await axios.post(`${global.domain}/empresas/`, empresa,config);
  return empresas;
}

export const eliminarEmpresa = (empresa) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const empresas = await axios.delete(`${global.domain}/empresas/${empresa.id}`,config);
  return empresas;
}

