import axios from 'axios'
import Crud from './crud';
// @ts-ignore

const client = new Crud("clientes/");

export const getGruposClientes = async () => {
  const data = await client.get({ url: "grupos" });
  return data || [];
}

// eslint-disable-next-line import/prefer-default-export
export const obtenerClientes = async () => {
  const token = localStorage.getItem('token')
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const clientes = await axios.get(`${global.domain}/clientes/`, config)
  return clientes
}

// eslint-disable-next-line import/prefer-default-export
export const obtenerMisClientes = (userId) => async () => {
  console.log(userId)
  const token = localStorage.getItem('token')
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const clientes = await axios.get(`${global.domain}/clientes/my/${userId}`, config)
  return clientes
}

export const editarCliente = (cliente) => async () => {
  const token = localStorage.getItem('token')
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const clientes = await axios.put(`${global.domain}/clientes/`, cliente, config)
  return clientes
}

export const crearCliente = (cliente) => async () => {
  const token = localStorage.getItem('token')
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const clientes = await axios.post(`${global.domain}/clientes/`, cliente, config)
  return clientes
}

export const eliminarCliente = (cliente) => async () => {
  const token = localStorage.getItem('token')
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const clientes = await axios.delete(`${global.domain}/clientes/${cliente.id}`, config)
  return clientes
}
