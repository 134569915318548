// import lodash from 'lodash';
import actions from './actions'

const initialState = {
  resumen: {},
  semanal: {},
  semanalExtraordinaria: {},
  decenal: {},
  catorcenal: {},
  quincenal: {},
  mensual: {},
  loading: true,
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case actions.LOAD_DASHBOARD:
      return {
        ...state,
        data: {
          ...state.data,
          resumen: action.payload,
        }
      }
    default:
      return state
  }
}
