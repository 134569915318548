/* eslint-disable no-debugger */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { login, currentAccount, logout } from 'services/user'
import actions from './actions'

export function* LOGIN({ payload }) {
  const { username, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(login, username, password)
  if (success) {
    notification.success({
      message: 'Correcto',
      description: 'Has iniciado sesión correctamente',
    })

    window.location.href = '/'

    // yield put({
    //   type: 'user/LOAD_CURRENT_ACCOUNT',
    // })
    // setTimeout(() => {window.location.href = '/'},500);
    
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  try {

    const response = yield call(currentAccount)

    if (response) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ...response,
          loading: false,
          authorized: true,
        },
      })

    }
  } catch (error) {
    console.error(error)
    yield put({
      type: actions.LOGOUT
    })
  }
}

export function* LOGOUT() {
  yield call(logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      nombre: '',
      rol: '',
      username: '',
      puesto: '',
      authorized: false,
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
