// import lodash from 'lodash';
import actions from './actions';

const initialState = {
  entities: [],
  index: {},
  data: {},
  loading: false,
}

export default function totalPercepciones(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state;
  }
}
