import Crud from './crud';
// @ts-ignore

const totalPercepciones = new Crud("total-percepciones/");

// eslint-disable-next-line import/prefer-default-export
export const getTotalPercepciones = async () => {
  const { error, entities, message } = await totalPercepciones.get();
  if (error) console.error("ErrorTotalPercepciones", message);
  return { data: error ? [] : entities};
}
