import { set } from 'object-path'
import * as actions from './actions'
import * as _type from '../../pages/solicitudes/constants/types'

const initialState = {
  requestType: _type.Alta,
  searchQuery: '',
  tiposDeSolicitud: [
    { key: _type.Alta },
    { key: _type.Baja },
    { key: _type.Aclaratoria },
    { key: _type.Patronal },
    { key: _type.Laboral },
    { key: _type.Dispersion },
    { key: _type.Fonacot },
    { key: _type.Guarderia },
    { key: _type.Incapacidad },
    { key: _type.Infonavit },
    { key: _type.Migracion },
    { key: _type.ModDatos },
    { key: _type.ModSalario },
    { key: _type.ST7 },
    { key: _type.Transfer },
    { key: _type.Visa },
    { key: _type.Renovacion },
    { key: _type.ModNomina },
  ],
  nuevaSolicitud: {
    tipo: _type.Alta,
    pasoActual: 0,
    propiedades: { numeroSolicitudes: 1 },
    empleados: {},
    stepDisabled: false,
  },
  entities_alta: [],
  entities_baja: [],
  entities_carta_aclaratoria: [],
  entities_carta_patronal: [],
  entities_constancia_laboral: [],
  entities_cuentas_dispersion: [],
  entities_fonacot: [],
  entities_guarderia: [],
  entities_incapacidad: [],
  entities_infonavit: [],
  entities_migracion: [],
  entities_mod_datos: [],
  entities_mod_salario: [],
  entities_mod_nomina: [],
  entities_st7: [],
  entities_transferencia_semanas: [],
  entities_visa: [],
  entities_renovacion: [],
  count: 0,
  loading: false,
}

function solicitudesReducer(state = initialState, action) {
  switch (action.type) {
    /** DEFAULTS  */
    case actions.default.SET_QUERY: {
      const newState = {
        ...state,
      }

      set(newState, 'searchQuery', action.payload)

      return newState
    }
    case actions.default.SET_SOL_EMPLOYEES: {
      const newState = {
        ...state,
      }

      set(newState, 'nuevaSolicitud.empleados', action.payload)

      return newState
    }
    case actions.default.SET_SOL_PROPS: {
      const newState = {
        ...state,
      }

      set(newState, 'nuevaSolicitud.propiedades', action.payload)

      return newState
    }
    case actions.default.SET_SOL_DATA: {
      const newState = {
        ...state,
      }

      set(newState, 'nuevaSolicitud.data', action.payload)

      return newState
    }

    case actions.default.SET_LOAD_TYPE: {
      const newState = {
        ...state,
      }

      set(newState, 'requestType', action.payload)

      return newState
    }

    case actions.default.SET_TIPO: {
      const newState = {
        ...state,
      }

      set(newState, 'nuevaSolicitud.tipo', action.payload)
      set(newState, 'nuevaSolicitud.pasoActual', 0)

      return newState
    }

    case actions.default.SET_DISABLE_NEXT: {
      const newState = {
        ...state,
      }

      set(newState, 'nuevaSolicitud.stepDisabled', action.payload)
      return newState
    }

    case actions.default.SET_STEP: {
      const newState = {
        ...state,
      }

      set(newState, 'nuevaSolicitud.pasoActual', action.payload)
      set(newState, 'nuevaSolicitud.stepDisabled', false)

      return newState
    }

    case actions.default.SET_STATE: {
      const newState = {
        ...state,
        ...action.payload,
      }

      return newState
    }

    /** Data Loaders */

    /** Alta */
    case actions.default.LOAD_SOLICITUDES_ALTA_FINISH: {
      const newState = {
        ...state,
        ...action.payload,
      }

      return newState
    }

    case actions.default.CREAR_SOLICITUD_ALTA: {
      const newState = {
        ...state,
        entities_alta: [...state.entities_alta, action.payload.entities_alta],
      }

      return newState
    }

    case actions.default.ELIMINAR_SOLICITUD_ALTA: {
      const newState = {
        ...state,
        entities_alta: state.entities_alta.filter((e) => e.id !== action.payload.id),
      }

      return newState
    }

    /** Baja */
    case actions.default.LOAD_SOLICITUDES_BAJA_FINISH: {
      const newState = {
        ...state,
        ...action.payload,
      }

      return newState
    }

    case actions.default.ELIMINAR_SOLICITUD_BAJA: {
      const newState = {
        ...state,
        entities_baja: state.entities_baja.filter((e) => e.id !== action.payload.id),
      }

      return newState
    }

    /** Carta Aclaratoria */
    case actions.default.LOAD_SOLICITUDES_CARTA_ACLARATORIA_FINISH: {
      const newState = {
        ...state,
        ...action.payload,
      }

      return newState
    }

    case actions.default.ELIMINAR_SOLICITUD_ACLARATORIA: {
      const newState = {
        ...state,
        entities_carta_aclaratoria: state.entities_carta_aclaratoria.filter((e) => e.id !== action.payload.id),
      }

      return newState
    }

    /** Carta Patronal */
    case actions.default.LOAD_SOLICITUDES_CARTA_PATRONAL_FINISH: {
      const newState = {
        ...state,
        ...action.payload,
      }

      return newState
    }

    case actions.default.ELIMINAR_SOLICITUD_CARTA_PATRONAL: {
      const newState = {
        ...state,
        entities_carta_patronal: state.entities_carta_patronal.filter((e) => e.id !== action.payload.id),
      }

      return newState
    }

    /** Constancia Laboral */
    case actions.default.LOAD_SOLICITUDES_CONSTANCIA_LABORAL_FINISH: {
      const newState = {
        ...state,
        ...action.payload,
      }

      return newState
    }

    case actions.default.ELIMINAR_SOLICITUD_CONSTANCIA_LABORAL: {
      const newState = {
        ...state,
        entities_constancia_laboral: state.entities_constancia_laboral.filter((e) => e.id !== action.payload.id),
      }

      return newState
    }

    /** Cuentas Dispersion */
    case actions.default.LOAD_SOLICITUDES_CUENTAS_DISPERSION_FINISH: {
      const newState = {
        ...state,
        ...action.payload,
      }

      return newState
    }

    case actions.default.ELIMINAR_SOLICITUD_CUENTAS_DISPERSION: {
      const newState = {
        ...state,
        entities_cuentas_dispersion: state.entities_cuentas_dispersion.filter((e) => e.id !== action.payload.id),
      }

      return newState
    }

    /** Fonacot */
    case actions.default.LOAD_SOLICITUDES_FONACOT_FINISH: {
      const newState = {
        ...state,
        ...action.payload,
      }

      return newState
    }

    case actions.default.ELIMINAR_SOLICITUD_FONACOT: {
      const newState = {
        ...state,
        entities_fonacot: state.entities_fonacot.filter((e) => e.id !== action.payload.id),
      }

      return newState
    }

    /** Guarderia */
    case actions.default.LOAD_SOLICITUDES_GUARDERIA_FINISH: {
      const newState = {
        ...state,
        ...action.payload,
      }

      return newState
    }

    case actions.default.ELIMINAR_SOLICITUD_GUARDERIA: {
      const newState = {
        ...state,
        entities_guarderia: state.entities_guarderia.filter((e) => e.id !== action.payload.id),
      }

      return newState
    }

    /** Incapacidad */
    case actions.default.LOAD_SOLICITUDES_INCAPACIDAD_FINISH: {
      const newState = {
        ...state,
        ...action.payload,
      }

      return newState
    }

    case actions.default.ELIMINAR_SOLICITUD_INCAPACIDAD: {
      const newState = {
        ...state,
        entities_incapacidad: state.entities_incapacidad.filter((e) => e.id !== action.payload.id),
      }

      return newState
    }

    /** Infonavit */
    case actions.default.LOAD_SOLICITUDES_INFONAVIT_FINISH: {
      const newState = {
        ...state,
        ...action.payload,
      }

      return newState
    }

    case actions.default.ELIMINAR_SOLICITUD_INFONAVIT: {
      const newState = {
        ...state,
        entities_infonavit: state.entities_infonavit.filter((e) => e.id !== action.payload.id),
      }

      return newState
    }

    /** Migracion */
    case actions.default.LOAD_SOLICITUDES_MIGRACION_FINISH: {
      const newState = {
        ...state,
        ...action.payload,
      }

      return newState
    }

    case actions.default.ELIMINAR_SOLICITUD_MIGRACION: {
      const newState = {
        ...state,
        entities_migracion: state.entities_migracion.filter((e) => e.id !== action.payload.id),
      }

      return newState
    }

    /** ModDatos */
    case actions.default.LOAD_SOLICITUDES_MOD_DATOS_FINISH: {
      const newState = {
        ...state,
        ...action.payload,
      }

      return newState
    }

    case actions.default.ELIMINAR_SOLICITUD_MOD_DATOS: {
      const newState = {
        ...state,
        entities_mod_datos: state.entities_mod_datos.filter((e) => e.id !== action.payload.id),
      }

      return newState
    }

    /** ModSalario */
    case actions.default.LOAD_SOLICITUDES_MOD_SALARIO_FINISH: {
      const newState = {
        ...state,
        ...action.payload,
      }

      return newState
    }

    case actions.default.ELIMINAR_SOLICITUD_MOD_SALARIO: {
      const newState = {
        ...state,
        entities_mod_salario: state.entities_mod_salario.filter((e) => e.id !== action.payload.id),
      }

      return newState
    }
    /** ModNomina */
    case actions.default.LOAD_SOLICITUDES_MOD_NOMINA_FINISH: {
      const newState = {
        ...state,
        ...action.payload,
      }

      return newState
    }

    case actions.default.ELIMINAR_SOLICITUD_MOD_NOMINA: {
      const newState = {
        ...state,
        entities_mod_nomina: state.entities_mod_nomina.filter((e) => e.id !== action.payload.id),
      }

      return newState
    }

    /** ST7 */
    case actions.default.LOAD_SOLICITUDES_ST7_FINISH: {
      const newState = {
        ...state,
        ...action.payload,
      }

      return newState
    }

    case actions.default.ELIMINAR_SOLICITUD_ST7: {
      const newState = {
        ...state,
        entities_st7: state.entities_st7.filter((e) => e.id !== action.payload.id),
      }

      return newState
    }

    /** Transferencia Semanas */
    case actions.default.LOAD_SOLICITUDES_TRANSFERENCIA_SEMANAS_FINISH: {
      const newState = {
        ...state,
        ...action.payload,
      }

      return newState
    }

    case actions.default.ELIMINAR_SOLICITUD_TRANSFERENCIA_SEMANAS: {
      const newState = {
        ...state,
        entities_transferencia_semanas: state.entities_transferencia_semanas.filter((e) => e.id !== action.payload.id),
      }

      return newState
    }

    /** Visa */
    case actions.default.LOAD_SOLICITUDES_VISA_FINISH: {
      const newState = {
        ...state,
        ...action.payload,
      }

      return newState
    }

    case actions.default.ELIMINAR_SOLICITUD_VISA: {
      const newState = {
        ...state,
        entities_visa: state.entities_visa.filter((e) => e.id !== action.payload.id),
      }

      return newState
    }

    /** Renovacion */
    case actions.default.LOAD_SOLICITUDES_RENOVACION_FINISH: {
      const newState = {
        ...state,
        ...action.payload,
      }

      return newState
    }

    case actions.default.ELIMINAR_SOLICITUD_RENOVACION: {
      const newState = {
        ...state,
        entities_renovacion: state.entities_renovacion.filter((e) => e.id !== action.payload.id),
      }

      return newState
    }

    default:
      return state
  }
}

export default solicitudesReducer
