import axios from 'axios'

/** LOADERS */

/** Export */
export const exportarSolicitudes = (requestType) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'POST',
    url: `${global.domain}/requests/export`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: {
      requestType,
    },
  }
  const response = axios.request(config)
  return response
}

// eslint-disable-next-line import/prefer-default-export
/** Alta */
export const getSolicitudesAlta = async (payload) => {
  const token = localStorage.getItem('token')
  const config = {
    method: 'POST',
    url: `${global.domain}/requests/alta/search`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: payload,
  }
  const solicitudes = await axios.request(config)
  return solicitudes
}

export const crearSolicitudAlta = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'POST',
    url: `${global.domain}/requests/alta/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const actualizarSolicitudAlta = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'PUT',
    url: `${global.domain}/requests/alta/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const eliminarSolicitudAlta = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'DELETE',
    url: `${global.domain}/requests/alta/${solicitud.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
  }
  const response = axios.request(config)
  return response
}

/** Baja */
export const getSolicitudesBaja = async (payload) => {
  const token = localStorage.getItem('token')
  const config = {
    method: 'POST',
    url: `${global.domain}/requests/baja/search`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: payload,
  }
  const solicitudes = await axios.request(config)
  return solicitudes
}

export const crearSolicitudBaja = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'POST',
    url: `${global.domain}/requests/baja/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const actualizarSolicitudBaja = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'PUT',
    url: `${global.domain}/requests/baja/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const eliminarSolicitudBaja = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'DELETE',
    url: `${global.domain}/requests/baja/${solicitud.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
  }
  const response = axios.request(config)
  return response
}

/** CartaAclaratoria */
export const getSolicitudesCartaAclaratoria = async (payload) => {
  const token = localStorage.getItem('token')
  const config = {
    method: 'POST',
    url: `${global.domain}/requests/aclaratoria/search`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: payload,
  }
  const solicitudes = await axios.request(config)
  return solicitudes
}

export const crearSolicitudCartaAclaratoria = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'POST',
    url: `${global.domain}/requests/aclaratoria/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const actualizarSolicitudAclaratoria = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'PUT',
    url: `${global.domain}/requests/aclaratoria/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const eliminarSolicitudAclaratoria = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'DELETE',
    url: `${global.domain}/requests/aclaratoria/${solicitud.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
  }
  const response = axios.request(config)
  return response
}

/** CartaPatronal */
export const getSolicitudesCartaPatronal = async (payload) => {
  const token = localStorage.getItem('token')
  const config = {
    method: 'POST',
    url: `${global.domain}/requests/patronal/search`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: payload,
  }
  const solicitudes = await axios.request(config)
  return solicitudes
}

export const crearSolicitudCartaPatronal = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'POST',
    url: `${global.domain}/requests/patronal/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const actualizarSolicitudPatronal = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'PUT',
    url: `${global.domain}/requests/patronal/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const eliminarSolicitudPatronal = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'DELETE',
    url: `${global.domain}/requests/patronal/${solicitud.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
  }
  const response = axios.request(config)
  return response
}

/** ConstanciaLaboral */
export const getSolicitudesConstanciaLaboral = async (payload) => {
  const token = localStorage.getItem('token')
  const config = {
    method: 'POST',
    url: `${global.domain}/requests/laboral/search`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: payload,
  }
  const solicitudes = await axios.request(config)
  return solicitudes
}

export const crearSolicitudConstanciaLaboral = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'POST',
    url: `${global.domain}/requests/laboral/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const actualizarSolicitudLaboral = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'PUT',
    url: `${global.domain}/requests/laboral/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const eliminarSolicitudLaboral = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'DELETE',
    url: `${global.domain}/requests/laboral/${solicitud.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
  }
  const response = axios.request(config)
  return response
}

/** CuentasDispersion */
export const getSolicitudesCuentasDispersion = async (payload) => {
  const token = localStorage.getItem('token')
  const config = {
    method: 'POST',
    url: `${global.domain}/requests/dispersion/search`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: payload,
  }
  const solicitudes = await axios.request(config)
  return solicitudes
}

export const crearSolicitudCuentasDispersion = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'POST',
    url: `${global.domain}/requests/dispersion/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const actualizarSolicitudDispersion = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'PUT',
    url: `${global.domain}/requests/dispersion/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const eliminarSolicituddispersion = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'DELETE',
    url: `${global.domain}/requests/dispersion/${solicitud.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
  }
  const response = axios.request(config)
  return response
}

/** Fonacot */
export const getSolicitudesFonacot = async (payload) => {
  const token = localStorage.getItem('token')
  const config = {
    method: 'POST',
    url: `${global.domain}/requests/fonacot/search`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: payload,
  }
  const solicitudes = await axios.request(config)
  return solicitudes
}

export const crearSolicitudFonacot = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'POST',
    url: `${global.domain}/requests/fonacot/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const actualizarSolicitudFonacot = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'PUT',
    url: `${global.domain}/requests/fonacot/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const eliminarSolicitudFonacot = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'DELETE',
    url: `${global.domain}/requests/fonacot/${solicitud.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
  }
  const response = axios.request(config)
  return response
}

/** Guarderia */
export const getSolicitudesGuarderia = async (payload) => {
  const token = localStorage.getItem('token')
  const config = {
    method: 'POST',
    url: `${global.domain}/requests/guarderia/search`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: payload,
  }
  const solicitudes = await axios.request(config)
  return solicitudes
}

export const crearSolicitudGuarderia = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'POST',
    url: `${global.domain}/requests/guarderia/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const actualizarSolicitudGuarderia = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'PUT',
    url: `${global.domain}/requests/guarderia/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const eliminarSolicitudGuarderia = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'DELETE',
    url: `${global.domain}/requests/guarderia/${solicitud.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
  }
  const response = axios.request(config)
  return response
}

/** Incapacidad */
export const getSolicitudesIncapacidad = async (payload) => {
  const token = localStorage.getItem('token')
  const config = {
    method: 'POST',
    url: `${global.domain}/requests/incapacidad/search`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: payload,
  }
  const solicitudes = await axios.request(config)
  return solicitudes
}

export const crearSolicitudIncapacidad = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'POST',
    url: `${global.domain}/requests/incapacidad/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const actualizarSolicitudIncapacidad = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'PUT',
    url: `${global.domain}/requests/incapacidad/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const eliminarSolicitudIncapacidad = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'DELETE',
    url: `${global.domain}/requests/incapacidad/${solicitud.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
  }
  const response = axios.request(config)
  return response
}

/** Migracion */
export const getSolicitudesInfonavit = async (payload) => {
  const token = localStorage.getItem('token')
  const config = {
    method: 'POST',
    url: `${global.domain}/requests/infonavit/search`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: payload,
  }
  const solicitudes = await axios.request(config)
  return solicitudes
}

export const crearSolicitudInfonavit = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'POST',
    url: `${global.domain}/requests/infonavit/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const actualizarSolicitudInfonavit = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'PUT',
    url: `${global.domain}/requests/infonavit/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const eliminarSolicitudInfonavit = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'DELETE',
    url: `${global.domain}/requests/infonavit/${solicitud.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
  }
  const response = axios.request(config)
  return response
}

/** Migracion */
export const getSolicitudesMigracion = async (payload) => {
  const token = localStorage.getItem('token')
  const config = {
    method: 'POST',
    url: `${global.domain}/requests/migracion/search`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: payload,
  }
  const solicitudes = await axios.request(config)
  return solicitudes
}

export const crearSolicitudMigracion = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'POST',
    url: `${global.domain}/requests/migracion/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const actualizarSolicitudMigracion = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'PUT',
    url: `${global.domain}/requests/migracion/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const eliminarSolicitudMigracion = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'DELETE',
    url: `${global.domain}/requests/migracion/${solicitud.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
  }
  const response = axios.request(config)
  return response
}

/** ModDatos */
export const getSolicitudesModDatos = async (payload) => {
  const token = localStorage.getItem('token')
  const config = {
    method: 'POST',
    url: `${global.domain}/requests/moddatos/search`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: payload,
  }
  const solicitudes = await axios.request(config)
  return solicitudes
}

export const crearSolicitudModDatos = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'POST',
    url: `${global.domain}/requests/moddatos/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const actualizarSolicitudModDatos = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'PUT',
    url: `${global.domain}/requests/moddatos/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const eliminarSolicitudModDatos = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'DELETE',
    url: `${global.domain}/requests/moddatos/${solicitud.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
  }
  const response = axios.request(config)
  return response
}

/** ModSalario */
export const getSolicitudesModSalario = async (payload) => {
  const token = localStorage.getItem('token')
  const config = {
    method: 'POST',
    url: `${global.domain}/requests/modsalario/search`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: payload,
  }
  const solicitudes = await axios.request(config)
  return solicitudes
}

export const crearSolicitudModSalario = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'POST',
    url: `${global.domain}/requests/modsalario/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const actualizarSolicitudModSalario = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'PUT',
    url: `${global.domain}/requests/modsalario/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const eliminarSolicitudModSalario = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'DELETE',
    url: `${global.domain}/requests/modsalario/${solicitud.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
  }
  const response = axios.request(config)
  return response
}

/** ST7 */
export const getSolicitudesST7 = async (payload) => {
  const token = localStorage.getItem('token')
  const config = {
    method: 'POST',
    url: `${global.domain}/requests/st7/search`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: payload,
  }
  const solicitudes = await axios.request(config)
  return solicitudes
}

export const crearSolicitudST7 = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'POST',
    url: `${global.domain}/requests/st7/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const actualizarSolicitudST7 = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'PUT',
    url: `${global.domain}/requests/st7/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const eliminarSolicitudST7 = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'DELETE',
    url: `${global.domain}/requests/st7/${solicitud.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
  }
  const response = axios.request(config)
  return response
}

/** TransferenciaSemanas */
export const getSolicitudesTransferenciaSemanas = async (payload) => {
  const token = localStorage.getItem('token')
  const config = {
    method: 'POST',
    url: `${global.domain}/requests/transfersemanas/search`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: payload,
  }
  const solicitudes = await axios.request(config)
  return solicitudes
}

export const crearSolicitudTransferenciaSemanas = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'POST',
    url: `${global.domain}/requests/transfersemanas/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const actualizarSolicitudTransferSemanas = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'PUT',
    url: `${global.domain}/requests/transfersemanas/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const eliminarSolicitudTransferSemanas = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'DELETE',
    url: `${global.domain}/requests/transfersemanas/${solicitud.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
  }
  const response = axios.request(config)
  return response
}

/** Visa */
export const getSolicitudesVisa = async (payload) => {
  const token = localStorage.getItem('token')
  const config = {
    method: 'POST',
    url: `${global.domain}/requests/visa/search`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: payload,
  }
  const solicitudes = await axios.request(config)
  return solicitudes
}

export const crearSolicitudVisa = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'POST',
    url: `${global.domain}/requests/visa/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const actualizarSolicitudVisa = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'PUT',
    url: `${global.domain}/requests/visa/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const eliminarSolicitudVisa = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'DELETE',
    url: `${global.domain}/requests/visa/${solicitud.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
  }
  const response = axios.request(config)
  return response
}

/** Renovacion */
export const getSolicitudesRenovacion = async (payload) => {
  const token = localStorage.getItem('token')
  const config = {
    method: 'POST',
    url: `${global.domain}/requests/renovacion/search`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: payload,
  }
  const solicitudes = await axios.request(config)
  return solicitudes
}

export const crearSolicitudRenovacion = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'POST',
    url: `${global.domain}/requests/renovacion/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const actualizarSolicitudRenovacion = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'PUT',
    url: `${global.domain}/requests/renovacion/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const eliminarSolicitudRenovacion = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'DELETE',
    url: `${global.domain}/requests/renovacion/${solicitud.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
  }
  const response = axios.request(config)
  return response
}

/** ModNomina */
export const getSolicitudesModNomina = async (payload) => {
  const token = localStorage.getItem('token')
  const config = {
    method: 'POST',
    url: `${global.domain}/requests/modnomina/search`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: payload,
  }
  const solicitudes = await axios.request(config)
  return solicitudes
}

export const crearSolicitudModNomina = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'POST',
    url: `${global.domain}/requests/modnomina/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const actualizarSolicitudModNomina = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'PUT',
    url: `${global.domain}/requests/modnomina/`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
    data: solicitud,
  }
  const response = axios.request(config)
  return response
}

export const eliminarSolicitudModNomina = (solicitud) => {
  const token = localStorage.getItem('token')

  const config = {
    method: 'DELETE',
    url: `${global.domain}/requests/modnomina/${solicitud.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version,
      'Content-Type': 'application/json',
    },
  }
  const response = axios.request(config)
  return response
}
