import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import bancos from './bancos/reducers'
import { clientes, misClientes } from './clientes/reducers'
import dashboard from './dashboard/reducers'
import empresas from './empresas/reducers'
import menu from './menu/reducers'
import nominas from './nominas/reducers'
import nominasPendientes from './nominasPendientes/reducers'
import periododenominas from './periodos/reducers'
import settings from './settings/reducers'
import tickets from './tickets/reducers'
import solicitudes from './solicitudes/reducers'
import peticiones from './peticiones/reducers'
import user from './user/reducers'
import usuarios from './usuarios/reducers'
import finiquitos from './finiquitos/reducers'
import totalPercepciones from './totalPercepciones/reducers'

export default (history) =>
  combineReducers({
    bancos,
    clientes,
    misClientes,
    tickets,
    dashboard,
    empresas,
    menu,
    nominas,
    nominasPendientes,
    periododenominas,
    router: connectRouter(history),
    settings,
    peticiones,
    solicitudes,
    user,
    usuarios,
    finiquitos,
    totalPercepciones,
  })
