/* eslint-disable no-debugger */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { obtenerPeriodoDeNominas, editarPeriodoDeNomina, crearPeriodoDeNomina, eliminarPeriodoDeNomina } from 'services/periododenominas'
import actions from './actions'

export function* LOAD_PERIODODENOMINAS() {
  yield put({
    type: 'periododenominas/SET_STATE',
    payload: {
      loading: true,
    },
  })

  try {

    const response = yield call(obtenerPeriodoDeNominas)

    if (response) {
      const { data: entities } = response
      yield put({
        type: 'periododenominas/SET_STATE',
        payload: {
          loading: false,
          entities
        },
      })

      // yield put({
      //   type: 'user/SET_STATE',
      //   payload: {
      //     loading: false,
      //   },
      // })
    }
  } catch (error) {

    const token = localStorage.getItem('token');
    if(token){
      notification.warning({
        message: "Hubo un error al obtener los periododenominas",
        description: "Verifica que estés conectado a internet",
      });
    }else{
      console.error(error)
    } ;
  }
}

export function* EDITAR_PERIODODENOMINA(periododenomina) {

  try {

    const response = yield call(editarPeriodoDeNomina(periododenomina.payload));

    if (response) {
      const { data: entities } = response
      yield put({
        type: 'periododenominas/SET_STATE',
        payload: {
          loading: false,
          entities
        },
      })

    }
  } catch (error) {
    notification.warning({
      message: "Hubo un error al editar el elemento",
      description: "Verifica que estés conectado a internet",
    });

  }
}

export function* CREAR_PERIODODENOMINA(periododenomina) {

  try {

    const response = yield call(crearPeriodoDeNomina(periododenomina.payload));

    if (response) {
      const { data: entities } = response
      yield put({
        type: 'periododenominas/SET_STATE',
        payload: {
          loading: false,
          entities
        },
      })

    }
  } catch (error) {
    notification.warning({
      message: "Hubo un error al crear el elemento",
      description: "Verifica que estés conectado a internet",
    });

  }
}

export function* ELIMINAR_PERIODODENOMINA(periododenomina) {

  try {

    const response = yield call(eliminarPeriodoDeNomina(periododenomina.payload));

    if (response) {
      const { data: entities } = response
      yield put({
        type: 'periododenominas/SET_STATE',
        payload: {
          loading: false,
          entities
        },
      })

    }
  } catch (error) {
    notification.warning({
      message: "Hubo un error al eliminar el elemento",
      description: "Verifica que estés conectado a internet",
    });

  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_PERIODODENOMINAS, LOAD_PERIODODENOMINAS),
    takeEvery(actions.EDITAR_PERIODODENOMINA, EDITAR_PERIODODENOMINA),
    takeEvery(actions.CREAR_PERIODODENOMINA, CREAR_PERIODODENOMINA),
    takeEvery(actions.ELIMINAR_PERIODODENOMINA, ELIMINAR_PERIODODENOMINA),
    LOAD_PERIODODENOMINAS(), // run once on app load to check user auth
  ])
}
