import antdData from 'antd/lib/locale-provider/en_US'
import localeData from 'react-intl/locale-data/en'

const messages = {

  'general.wave': 'Hello',

  'buttons.view': 'See details',
  'buttons.cancel': 'Cancel',

  'general.lastVisit': 'Last consultation',

  'breadcrumb.home': 'Home',

  'menu.calendar': 'Nail calendar',
  'menu.settlements': 'Finiquitos.',
  'menu.settings': 'Setting',
  'menu.patients': 'Patients',
  'menu.dashboard': 'Dashboard',
  'menu.nominas.familias': 'Familias de nominas',

  'menu.nominas': 'Base payroll',
  'menu.nominaEmisora': 'Commisher payrolls',
  'menu.nominasPendientes': 'Pending payrolls',
  'menu.claveNominas': 'Fix key to payroll',
  'menu.planaNominas': 'Fixing flat nomine (if applicable) to payroll',
  'menu.periodos': 'Types of period',
  'menu.empresas': 'Companies',
  'menu.clientes': 'Customers',
  'menu.provisiones':'Provisiones',
  'menu.solicitudes': 'Requests',
  'menu.peticiones': 'Requests',
  'menu.ADC': 'adc',
  'menu.usuarios': 'Users',
  'menu.bancos': 'Banks',
  'menu.totalPercepciones': 'Total perceptions',

  'user.name': 'Name',
  'user.photo': 'Photo',

  'search.placeholder.name': 'Enter a name',
  'search.button': 'Look for',

  'calendar.title': 'Nail calendar',

  'topBar.typeToSearch': 'Type to search...',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',
  'topBar.bitcoin': 'Bitcoin',
  'topBar.buyNow': 'Buy Now $24',
  'patientList.title': 'Patient List',
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',

}

export default {
  locale: 'en-US',
  antdData,
  localeData,
  messages,
}
