import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import reducers from 'redux/reducers'
import sagas from 'redux/sagas'
import Router from 'router'
import Localization from 'components/LayoutComponents/Localization'
import * as serviceWorker from './serviceWorker'
import { version, domain } from './helpers/domains'

// app styles
import './global.scss'

// @ts-ignore
global.versionNumber = version
global.version = `v1.${global.versionNumber}.341`

global.domain = domain

if (String(window.location.hostname).includes('.app')) {
  global.domain = `https://api-ops-${global.versionNumber}.levita.app`
}
global.ws = 'wss://0iobfg5sgl.execute-api.us-east-1.amazonaws.com/Prod'

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [thunk, sagaMiddleware, routeMiddleware]

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

localStorage.removeItem('connected')

const store = createStore(reducers(history), composeEnhancers(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

ReactDOM.render(
  <Provider store={store}>
    <Localization>
      <Router history={history} />
    </Localization>
  </Provider>,
  document.getElementById('root'),
)

serviceWorker.unregister()
export { store, history }
