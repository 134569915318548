
// pass your tawk.to path
module.exports = function tawkTo(path, name, email, id) {
  if (!window) {
    throw new Error('DOM is unavailable');
  }
  window.Tawk_API = window.Tawk_API || {};

  window.Tawk_LoadStart = new Date();

  // pass attributes to tawk.to on widget load
  window.Tawk_API.onLoad = () => {
    window.Tawk_API.setAttributes(
      {
        name,
        email,
        id,
      },
      () => {},
    );
  };

  const tawk = document.getElementById('tawkId');
  if (tawk) {
    // Prevent TawkTo to create root script if it already exists
    return window.Tawk_API;
  }

  const script = document.createElement('script');
  script.id = 'tawkId';
  script.async = true;
  script.src = `https://embed.tawk.to/${path}`;
  script.charset = 'UTF-8';
  script.setAttribute('crossorigin', '*');

  const firstScriptTag = document.getElementsByTagName('script')[0];
  if (!firstScriptTag || !firstScriptTag.parentNode) {
    throw new Error('DOM is unavailable');
  }

  firstScriptTag.parentNode.insertBefore(script, firstScriptTag);

  return null
};