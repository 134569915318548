/* eslint-disable no-debugger */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { obtenerNominasPendientes } from 'services/nominas-pendientes'
import actions from './actions'

export function* LOAD_NOMINAS_PENDIENTES() {
  yield put({
    type: 'nominaspendientes/SET_STATE',
    payload: {
      loading: true,
    },
  })

  try {
    const response = yield call(obtenerNominasPendientes)

    if (response) {
      const { data: entities } = response

      yield put({
        type: 'nominaspendientes/SET_STATE',
        payload: {
          loading: false,
          entities
        },
      })

      // yield put({
      //   type: 'user/SET_STATE',
      //   payload: {
      //     loading: false,
      //   },
      // })
    }
  } catch (error) {
    const token = localStorage.getItem('token');
    if(token){
      notification.warning({
        message: "Hubo un error al obtener los nominas pendientes",
        description: "Verifica que estés conectado a internet",
      });
    }else{
      console.error(error)
    } ;
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_NOMINAS_PENDIENTES, LOAD_NOMINAS_PENDIENTES),
    LOAD_NOMINAS_PENDIENTES(), // run once on app load to check user auth
  ])
}
