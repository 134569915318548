/* eslint-disable no-debugger */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import lodash from 'lodash'
import {
  getSolicitudesAlta,
  getSolicitudesBaja,
  getSolicitudesCartaAclaratoria,
  getSolicitudesCartaPatronal,
  getSolicitudesConstanciaLaboral,
  getSolicitudesCuentasDispersion,
  getSolicitudesFonacot,
  getSolicitudesGuarderia,
  getSolicitudesIncapacidad,
  getSolicitudesInfonavit,
  getSolicitudesMigracion,
  getSolicitudesModDatos,
  getSolicitudesModSalario,
  getSolicitudesST7,
  getSolicitudesTransferenciaSemanas,
  getSolicitudesVisa,
  getSolicitudesRenovacion,
  getSolicitudesModNomina,
} from 'services/solicitudes'
import actions from './actions'
import * as _type from '../../pages/solicitudes/constants/types'

/** Alta */
export function* LOAD_SOLICITUDES_ALTA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      requestType: _type.Alta,
      searchQuery: '',
    },
  })

  try {
    const response = yield call(getSolicitudesAlta, payload)

    if (response) {
      const { data: resData } = response
      yield put({
        type: actions.LOAD_SOLICITUDES_ALTA_FINISH,
        payload: {
          loading: false,
          entities_alta: resData.entities ? resData.entities : [],
          count: resData.count,
          index: lodash.keyBy(resData.entities, 'id'),
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al obtener las solicitudes de Alta',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

/** Baja */
export function* LOAD_SOLICITUDES_BAJA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      requestType: _type.Baja,
      searchQuery: '',
    },
  })
  try {
    const response = yield call(getSolicitudesBaja, payload)

    if (response) {
      const { data: resData } = response

      yield put({
        type: actions.LOAD_SOLICITUDES_BAJA_FINISH,
        payload: {
          loading: false,
          entities_baja: resData.entities ? resData.entities : [],
          count: resData.count,
          index: lodash.keyBy(resData.entities, 'id'),
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al obtener las solicitudes de Baja',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

/** CartaAclaratoria */
export function* LOAD_SOLICITUDES_CARTA_ACLARATORIA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      requestType: _type.Aclaratoria,
      searchQuery: '',
    },
  })

  try {
    const response = yield call(getSolicitudesCartaAclaratoria, payload)

    if (response) {
      const { data: resData } = response
      yield put({
        type: actions.LOAD_SOLICITUDES_CARTA_ACLARATORIA_FINISH,
        payload: {
          loading: false,
          entities_carta_aclaratoria: resData.entities ? resData.entities : [],
          count: resData.count,
          index: lodash.keyBy(resData.entities, 'id'),
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al obtener las solicitudes de Carta Aclaratoria',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

/** CartaPatronal */
export function* LOAD_SOLICITUDES_CARTA_PATRONAL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      requestType: _type.Patronal,
      searchQuery: '',
    },
  })

  try {
    const response = yield call(getSolicitudesCartaPatronal, payload)

    if (response) {
      const { data: resData } = response

      yield put({
        type: actions.LOAD_SOLICITUDES_CARTA_PATRONAL_FINISH,
        payload: {
          loading: false,
          entities_carta_patronal: resData.entities ? resData.entities : [],
          count: resData.count,
          index: lodash.keyBy(resData.entities, 'id'),
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al obtener las solicitudes de Carta Patronal',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

/** CosntanciaLaboral */
export function* LOAD_SOLICITUDES_CONSTANCIA_LABORAL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      requestType: _type.Laboral,
      searchQuery: '',
    },
  })

  try {
    const response = yield call(getSolicitudesConstanciaLaboral, payload)

    if (response) {
      const { data: resData } = response

      yield put({
        type: actions.LOAD_SOLICITUDES_CONSTANCIA_LABORAL_FINISH,
        payload: {
          loading: false,
          entities_constancia_laboral: resData.entities ? resData.entities : [],
          count: resData.count,
          index: lodash.keyBy(resData.entities, 'id'),
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al obtener las solicitudes de Constancia Laboral',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

/** CuentasDispersion */
export function* LOAD_SOLICITUDES_CUENTAS_DISPERSION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      requestType: _type.Dispersion,
      searchQuery: '',
    },
  })

  try {
    const response = yield call(getSolicitudesCuentasDispersion, payload)

    if (response) {
      const { data: resData } = response

      yield put({
        type: actions.LOAD_SOLICITUDES_CUENTAS_DISPERSION_FINISH,
        payload: {
          loading: false,
          entities_cuentas_dispersion: resData.entities ? resData.entities : [],
          count: resData.count,
          index: lodash.keyBy(resData.entities, 'id'),
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al obtener las solicitudes de Cuentas de dispersion',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

/** Fonacot */
export function* LOAD_SOLICITUDES_FONACOT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      requestType: _type.Fonacot,
      searchQuery: '',
    },
  })

  try {
    const response = yield call(getSolicitudesFonacot, payload)

    if (response) {
      const { data: resData } = response

      yield put({
        type: actions.LOAD_SOLICITUDES_FONACOT_FINISH,
        payload: {
          loading: false,
          entities_fonacot: resData.entities ? resData.entities : [],
          count: resData.count,
          index: lodash.keyBy(resData.entities, 'id'),
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al obtener las solicitudes de Fonacot',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

/** Guarderia */
export function* LOAD_SOLICITUDES_GUARDERIA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      requestType: _type.Guarderia,
      searchQuery: '',
    },
  })

  try {
    const response = yield call(getSolicitudesGuarderia, payload)

    if (response) {
      const { data: resData } = response
      yield put({
        type: actions.LOAD_SOLICITUDES_GUARDERIA_FINISH,
        payload: {
          loading: false,
          entities_guarderia: resData.entities ? resData.entities : [],
          count: resData.count,
          index: lodash.keyBy(resData.entities, 'id'),
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al obtener las solicitudes de Guarderia',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

/** Incapacidad */
export function* LOAD_SOLICITUDES_INCAPACIDAD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      requestType: _type.Incapacidad,
      searchQuery: '',
    },
  })

  try {
    const response = yield call(getSolicitudesIncapacidad, payload)

    if (response) {
      const { data: resData } = response
      yield put({
        type: actions.LOAD_SOLICITUDES_INCAPACIDAD_FINISH,
        payload: {
          loading: false,
          entities_incapacidad: resData.entities ? resData.entities : [],
          count: resData.count,
          index: lodash.keyBy(resData.entities, 'id'),
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al obtener las solicitudes de Incapacidad',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

/** INFONAVIT */
export function* LOAD_SOLICITUDES_INFONAVIT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      requestType: _type.Infonavit,
      searchQuery: '',
    },
  })

  try {
    const response = yield call(getSolicitudesInfonavit, payload)

    if (response) {
      const { data: resData } = response
      yield put({
        type: actions.LOAD_SOLICITUDES_INFONAVIT_FINISH,
        payload: {
          loading: false,
          entities_infonavit: resData.entities ? resData.entities : [],
          count: resData.count,
          index: lodash.keyBy(resData.entities, 'id'),
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al obtener las solicitudes de Infonavit',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

/** MIGRACION */
export function* LOAD_SOLICITUDES_MIGRACION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      requestType: _type.Migracion,
      searchQuery: '',
    },
  })

  try {
    const response = yield call(getSolicitudesMigracion, payload)

    if (response) {
      const { data: resData } = response
      yield put({
        type: actions.LOAD_SOLICITUDES_MIGRACION_FINISH,
        payload: {
          loading: false,
          entities_migracion: resData.entities ? resData.entities : [],
          count: resData.count,
          index: lodash.keyBy(resData.entities, 'id'),
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al obtener las solicitudes de Migracion',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

/** MOD DATOS */
export function* LOAD_SOLICITUDES_MOD_DATOS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      requestType: _type.ModDatos,
      searchQuery: '',
    },
  })

  try {
    const response = yield call(getSolicitudesModDatos, payload)

    if (response) {
      const { data: resData } = response
      yield put({
        type: actions.LOAD_SOLICITUDES_MOD_DATOS_FINISH,
        payload: {
          loading: false,
          entities_mod_datos: resData.entities ? resData.entities : [],
          count: resData.count,
          index: lodash.keyBy(resData.entities, 'id'),
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al obtener las solicitudes de Modificacion de Datos',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

/** MODSALARIO */
export function* LOAD_SOLICITUDES_MOD_SALARIO({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      requestType: _type.ModSalario,
      searchQuery: '',
    },
  })

  try {
    const response = yield call(getSolicitudesModSalario, payload)

    if (response) {
      const { data: resData } = response
      yield put({
        type: actions.LOAD_SOLICITUDES_MOD_SALARIO_FINISH,
        payload: {
          loading: false,
          entities_mod_salario: resData.entities ? resData.entities : [],
          count: resData.count,
          index: lodash.keyBy(resData.entities, 'id'),
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al obtener las solicitudes de Modificacion Salario',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

/** ST7 */
export function* LOAD_SOLICITUDES_ST7({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      requestType: _type.ST7,
      searchQuery: '',
    },
  })

  try {
    const response = yield call(getSolicitudesST7, payload)

    if (response) {
      const { data: resData } = response
      yield put({
        type: actions.LOAD_SOLICITUDES_ST7_FINISH,
        payload: {
          loading: false,
          entities_st7: resData.entities ? resData.entities : [],
          count: resData.count,
          index: lodash.keyBy(resData.entities, 'id'),
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al obtener las solicitudes de ST7',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

/** TRANSFERENCIA_SEMANAS */
export function* LOAD_SOLICITUDES_TRANSFERENCIA_SEMANAS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      requestType: _type.Transfer,
      searchQuery: '',
    },
  })

  try {
    const response = yield call(getSolicitudesTransferenciaSemanas, payload)

    if (response) {
      const { data: resData } = response
      yield put({
        type: actions.LOAD_SOLICITUDES_TRANSFERENCIA_SEMANAS_FINISH,
        payload: {
          loading: false,
          entities_transferencia_semanas: resData.entities ? resData.entities : [],
          count: resData.count,
          index: lodash.keyBy(resData.entities, 'id'),
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al obtener las solicitudes de Transferencia de Semanas',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

/** VISA */
export function* LOAD_SOLICITUDES_VISA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      requestType: _type.Visa,
      searchQuery: '',
    },
  })

  try {
    const response = yield call(getSolicitudesVisa, payload)

    if (response) {
      const { data: resData } = response
      yield put({
        type: actions.LOAD_SOLICITUDES_VISA_FINISH,
        payload: {
          loading: false,
          entities_visa: resData.entities ? resData.entities : [],
          count: resData.count,
          index: lodash.keyBy(resData.entities, 'id'),
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al obtener las solicitudes de Visa',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

/** RENOVACION */
export function* LOAD_SOLICITUDES_RENOVACION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      requestType: _type.Renovacion,
      searchQuery: '',
    },
  })

  try {
    const response = yield call(getSolicitudesRenovacion, payload)

    if (response) {
      const { data: resData } = response
      yield put({
        type: actions.LOAD_SOLICITUDES_RENOVACION_FINISH,
        payload: {
          loading: false,
          entities_renovacion: resData.entities ? resData.entities : [],
          count: resData.count,
          index: lodash.keyBy(resData.entities, 'id'),
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al obtener las solicitudes de Renovacion',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

/** MODNOMINA */
export function* LOAD_SOLICITUDES_MOD_NOMINA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      requestType: _type.ModNomina,
      searchQuery: '',
    },
  })

  try {
    const response = yield call(getSolicitudesModNomina, payload)

    if (response) {
      const { data: resData } = response
      yield put({
        type: actions.LOAD_SOLICITUDES_MOD_NOMINA_FINISH,
        payload: {
          loading: false,
          entities_mod_nomina: resData.entities ? resData.entities : [],
          count: resData.count,
          index: lodash.keyBy(resData.entities, 'id'),
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al obtener las solicitudes de Modificacion Nomina',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_SOLICITUDES_ALTA, LOAD_SOLICITUDES_ALTA),
    takeEvery(actions.LOAD_SOLICITUDES_BAJA, LOAD_SOLICITUDES_BAJA),
    takeEvery(actions.LOAD_SOLICITUDES_CARTA_ACLARATORIA, LOAD_SOLICITUDES_CARTA_ACLARATORIA),
    takeEvery(actions.LOAD_SOLICITUDES_CARTA_PATRONAL, LOAD_SOLICITUDES_CARTA_PATRONAL),
    takeEvery(actions.LOAD_SOLICITUDES_CONSTANCIA_LABORAL, LOAD_SOLICITUDES_CONSTANCIA_LABORAL),
    takeEvery(actions.LOAD_SOLICITUDES_CUENTAS_DISPERSION, LOAD_SOLICITUDES_CUENTAS_DISPERSION),
    takeEvery(actions.LOAD_SOLICITUDES_FONACOT, LOAD_SOLICITUDES_FONACOT),
    takeEvery(actions.LOAD_SOLICITUDES_GUARDERIA, LOAD_SOLICITUDES_GUARDERIA),
    takeEvery(actions.LOAD_SOLICITUDES_INCAPACIDAD, LOAD_SOLICITUDES_INCAPACIDAD),
    takeEvery(actions.LOAD_SOLICITUDES_INFONAVIT, LOAD_SOLICITUDES_INFONAVIT),
    takeEvery(actions.LOAD_SOLICITUDES_MIGRACION, LOAD_SOLICITUDES_MIGRACION),
    takeEvery(actions.LOAD_SOLICITUDES_MOD_DATOS, LOAD_SOLICITUDES_MOD_DATOS),
    takeEvery(actions.LOAD_SOLICITUDES_MOD_NOMINA, LOAD_SOLICITUDES_MOD_NOMINA),
    takeEvery(actions.LOAD_SOLICITUDES_MOD_SALARIO, LOAD_SOLICITUDES_MOD_SALARIO),
    takeEvery(actions.LOAD_SOLICITUDES_ST7, LOAD_SOLICITUDES_ST7),
    takeEvery(actions.LOAD_SOLICITUDES_TRANSFERENCIA_SEMANAS, LOAD_SOLICITUDES_TRANSFERENCIA_SEMANAS),
    takeEvery(actions.LOAD_SOLICITUDES_VISA, LOAD_SOLICITUDES_VISA),
    takeEvery(actions.LOAD_SOLICITUDES_RENOVACION, LOAD_SOLICITUDES_RENOVACION),
    // LOAD_SOLICITUDES_ALTA(), // run once on app load to check user auth
  ])
}
