const actions = {
  SET_STATE: 'nominas/SET_STATE',
  SET_STATE_TRABAJADA: 'nominas/SET_STATE_TRABAJADA',
  SET_STATE_TRABAJADA_WS: 'nominas/SET_STATE_TRABAJADA_WS',
  LOAD_NOMINAS: 'nominas/LOAD_NOMINAS',
  GET_DATA_FROM_NOMINA: 'nominas/GET_DATA_FROM_NOMINA',
  GET_DATA_FROM_NOMINAS_PENDIENTES: 'nominas/GET_DATA_FROM_NOMINAS_PENDIENTES',
  EDITAR_NOMINA: 'nominas/EDITAR_NOMINA',
  EDITAR_NOMINA_TRABAJADA: 'nominas/EDITAR_NOMINA_TRABAJADA',
  SET_DATA_STATE: 'nominas/SET_DATA_STATE',
  CREAR_NOMINA: 'nominas/CREAR_NOMINA',
  ELIMINAR_NOMINA: 'nominas/ELIMINAR_NOMINA',
}

export default actions
