import axios from 'axios'
// import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const obtenerNominas = async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const nominas = await axios.get(`${global.domain}/nominas/`, config);
  return nominas;
}

export const obtenerDatosNominas = (frecuencia, start, end) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  // const startDB = moment(new Date(start)).format('YYYY-MM-DD HH:mm:ss')
  // const endDB = moment(new Date(end)).format('YYYY-MM-DD HH:mm:ss')
  // console.log(startDB, endDB)
  const nominas = await axios.post(`${global.domain}/nominas/data/${frecuencia}/`, {
    start, end
  }, config);
  return nominas;
}

export const editarNomina = (nomina) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const nominas = await axios.put(`${global.domain}/nominas/`, nomina, config);
  return nominas;
}

export const editarNominaTrabajada = (nominaTrabajadaData) => async () => {
  
  console.warn(nominaTrabajadaData)

  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      Version: global.version
    }
  };
  
  try {
    const nominaTrabajada = await axios.put(`${global.domain}/nominas/trabajada/`, nominaTrabajadaData, config);
    return nominaTrabajada;
  } catch (err) {
    console.error(err);
    return false;
  }
}

export const crearNomina = (nomina) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const nominas = await axios.post(`${global.domain}/nominas/`, nomina, config);
  return nominas;
}

export const eliminarNomina = (nomina) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const nominas = await axios.delete(`${global.domain}/nominas/${nomina.id}`,config);
  return nominas;
}

