import axios from 'axios';
import { notification } from 'antd'
import tawkto from './tawkto';

export async function login(username, password) {

  const response = await axios({
    method: "POST",
    url: `${global.domain}/login`,
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    data: {
      username,
      password
    }
  })

  if (response.data.error) {
    notification.warning({
      message: "Hay un error",
      description: response.data.error,
    })
    return false;
  }

  localStorage.setItem('token', response.data.token);
  return true;
}

export async function currentAccount() {
  const token = localStorage.getItem('token');

  function getCurrentUser() {
    return new Promise((resolve, reject) => {
      if (token) {
        axios({
          "method": "GET",
          "url": `${global.domain}/me`,
          "headers": {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json; charset=utf-8"
          }
        }).then(response => {
          console.log(response);
          if (response.data !== '') {
            resolve(response.data);

            const {nombre, email, id} = response.data

            tawkto(
              '609c3d58b1d5182476b85f15/1f5h4v8gl',
              nombre, // nombre
              email, // email
              id,
            );

          } else {
            localStorage.clear();
            reject();
          }
        }).catch(err => {
          localStorage.clear();
          reject(err);
        })
      } else {
        reject(new Error("Hubo un error"));
      }
    });
  }

  return getCurrentUser()
}

export async function logout() {
  localStorage.clear();
  return true;
}
