/* eslint-disable no-debugger */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification, message } from 'antd'
import lodash from 'lodash'
import {
  obtenerNominas,
  editarNomina,
  crearNomina,
  eliminarNomina,
  obtenerDatosNominas,
  editarNominaTrabajada,
} from 'services/nominas'
import { obtenerNominasPendientes } from 'services/nominas-pendientes'
import actions from './actions'

export function* GET_DATA_FROM_NOMINA({ payload: { frecuencia, rangeStart, rangeEnd } }) {
  yield put({
    type: 'nominas/SET_STATE',
    payload: {
      loading: true,
      data: {},
    },
  })

  try {
    const response = yield call(obtenerDatosNominas(frecuencia, rangeStart, rangeEnd))
    if (response) {
      const { data: entities } = response
      yield put({
        type: 'nominas/SET_STATE',
        payload: {
          loading: false,
          data: entities.reduce((acc, item) => {
            return {
              ...acc,
              [item.id]: item,
            }
          }, {}),
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al obtener los nominas',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

export function* GET_DATA_FROM_NOMINAS_PENDIENTES() {
  yield put({
    type: 'nominas/SET_STATE',
    payload: {
      loading: true,
      data: {},
    },
  })

  try {
    const response = yield call(obtenerNominasPendientes)
    if (response) {
      const { data: entities } = response
      yield put({
        type: 'nominas/SET_STATE',
        payload: {
          loading: false,
          data: entities.reduce((acc, item) => {
            return {
              ...acc,
              [item.id]: item,
            }
          }, {}),
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al obtener los nominas',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

export function* LOAD_NOMINAS() {
  yield put({
    type: 'nominas/SET_STATE',
    payload: {
      loading: true,
    },
  })

  try {
    const response = yield call(obtenerNominas)

    if (response) {
      const { data: entities } = response
      yield put({
        type: 'nominas/SET_STATE',
        payload: {
          loading: false,
          entities,
          index: lodash.keyBy(entities, 'id'),
        },
      })

      // yield put({
      //   type: 'user/SET_STATE',
      //   payload: {
      //     loading: false,
      //   },
      // })
    }
  } catch (error) {
    const token = localStorage.getItem('token');
    if(token){
      notification.warning({
        message: 'Hubo un error al obtener los nominas',
        description: 'Verifica que estés conectado a internet',
      })
    }else{
      console.error(error)
    } ;
  }
}

export function* EDITAR_NOMINA(nomina) {
  try {
    const response = yield call(editarNomina(nomina.payload))

    if (response) {
      const { data: entities } = response
      yield put({
        type: 'nominas/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      })

      message.success('Nómina guardada correctamente')
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al editar el elemento',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

export function* EDITAR_NOMINA_TRABAJADA(nomina) {
  const { nombre = '' } = nomina.payload
  const hide = message.loading(`Guardando ${nombre}...`, 0)
  try {
    const response = yield call(editarNominaTrabajada(nomina.payload))
    hide()
    if (response) {
      message.success(`Se guardó correctamente la nómina ${nombre}`)
    } else {
      message.warning(`Hubo un problema con tu conexión de internet, no se pudo guardar ${nombre}`)
    }
  } catch (error) {
    hide()

    notification.warning({
      message: 'Hubo un error al editar el elemento',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

export function* CREAR_NOMINA(nomina) {
  try {
    const response = yield call(crearNomina(nomina.payload))

    if (response) {
      const { data: entities } = response
      yield put({
        type: 'nominas/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al crear el elemento',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

export function* ELIMINAR_NOMINA(nomina) {
  try {
    const response = yield call(eliminarNomina(nomina.payload))

    if (response) {
      const { data: entities } = response
      yield put({
        type: 'nominas/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      })
    }
  } catch (error) {
    notification.warning({
      message: 'Hubo un error al eliminar el elemento',
      description: 'Verifica que estés conectado a internet',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_NOMINAS, LOAD_NOMINAS),
    takeEvery(actions.GET_DATA_FROM_NOMINA, GET_DATA_FROM_NOMINA),
    takeEvery(actions.GET_DATA_FROM_NOMINAS_PENDIENTES, GET_DATA_FROM_NOMINAS_PENDIENTES),
    takeEvery(actions.EDITAR_NOMINA, EDITAR_NOMINA),
    takeEvery(actions.EDITAR_NOMINA_TRABAJADA, EDITAR_NOMINA_TRABAJADA),
    takeEvery(actions.CREAR_NOMINA, CREAR_NOMINA),
    takeEvery(actions.ELIMINAR_NOMINA, ELIMINAR_NOMINA),
    LOAD_NOMINAS(), // run once on app load to check user auth
  ])
}
