/* eslint-disable no-debugger */
import { all, takeEvery, put, call } from 'redux-saga/effects'
// import { notification } from 'antd'
import {
  obtenerDashboard,
} from 'services/dashboard'
import actions from './actions'

export function* GET_DASHBOARD({ payload: { date } }) {

  yield put({
    type: 'dashboard/SET_STATE',
    payload: {
      loading: true,
    },
  })

  localStorage.setItem('date', date)

  try {
    const response = yield call(obtenerDashboard(date))
    if (response) {
      const { data: {
        controlMaestroSemanalExtraordinaria,
        controlMaestroSemanal,
        controlMaestroDecenal,
        controlMaestroCatorcenal,
        controlMaestroQuincenal,
        controlMaestroMensual,
        controlMaestroAguinaldo,
      } } = response
      yield put({
        type: 'dashboard/SET_STATE',
        payload: {
          loading: false,
          resumen: {
            semanalExtraordinaria: controlMaestroSemanalExtraordinaria,
            semanal: controlMaestroSemanal,
            decenal: controlMaestroDecenal,
            catorcenal: controlMaestroCatorcenal,
            quincenal: controlMaestroQuincenal,
            mensual: controlMaestroMensual,
            aguinaldo: controlMaestroAguinaldo,
          },
          semanal: controlMaestroSemanal,
          semanalExtraordinaria: controlMaestroSemanalExtraordinaria,
          decenal: controlMaestroDecenal,
          catorcenal: controlMaestroCatorcenal,
          quincenal: controlMaestroQuincenal,
          aguinaldo: controlMaestroAguinaldo,
          mensual: controlMaestroMensual,
        },
      })
    }

    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        loading: true,
      },
    })
  } catch (error) {

    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        loading: false,
      },
    })
    const token = localStorage.getItem('token');
    if(token){
      // notification.warning({
      //   message: "Hubo un error al obtener los dashboard",
      //   description: "Verifica que estés conectado a internet",
      // });
    }else{
      console.error(error)
    } 
  }

}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DASHBOARD, GET_DASHBOARD),
    // LOAD_DASHBOARD(), // run once on app load to check user auth
  ])
}
