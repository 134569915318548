import antdData from 'antd/lib/locale-provider/es_ES'
import localeData from 'react-intl/locale-data/es'

const messages = {

  'general.wave': 'Hola',

  'buttons.view': 'Ver detalles',
  'buttons.cancel': 'Cancelar',

  'general.lastVisit': 'Ultima consulta',

  'breadcrumb.home': 'Inicio',

  'menu.calendar': 'Calendario de nóminas',
  'menu.settlements': 'Finiquitos',
  'menu.settings': 'Configuración',
  'menu.patients': 'Pacientes',
  'menu.dashboard': 'Dashboard',

  'menu.nominas.familias': 'Familias de nominas',
  'menu.nominas': 'Nóminas base',
  'menu.nominaEmisora': 'Nóminas emisora',
  'menu.nominasPendientes': 'Nóminas pendientes',
  'menu.claveNominas': 'Fijar clave a las Nóminas',
  'menu.planaNominas': 'Fijar nomina plana (si aplica) a las Nóminas',
  'menu.periodos': 'Tipos de periodo',
  'menu.empresas': 'Empresas',
  'menu.clientes': 'Clientes',
  'menu.provisiones':'Provisiones',
  'menu.solicitudes': 'Solicitudes',
  'menu.tickets': 'Tickets de soporte',
  'menu.peticiones': 'Peticiones',
  'menu.ADC': 'ADC',
  'menu.usuarios': 'Usuarios',
  'menu.bancos': 'Bancos',
  'menu.totalPercepciones': 'Total percepciones',

  'user.name': 'Nombre',
  'user.photo': 'Foto',

  'search.placeholder.name': 'Ingresa un nombre',
  'search.button': 'Buscar',

  'calendar.title': 'Calendario de nóminas',

  'topBar.typeToSearch': 'Escribe para buscar...',
  'topBar.profileMenu.hello': 'Hola',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Rol',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Teléfono',
  'topBar.profileMenu.editProfile': 'Editar perfil',
  'topBar.profileMenu.logout': 'Salir',
  'topBar.bitcoin': 'Bitcoin',
  'topBar.buyNow': 'Compra ahora $24',
  'patientList.title': 'Lista de pacientes ',
  'topBar.issuesHistory': 'Historial de problemas',
  'topBar.projectManagement': 'Gestión de proyectos',

}

export default {
  locale: 'es-LA',
  antdData,
  localeData,
  messages,
}
