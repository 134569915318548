import axios from 'axios'

// eslint-disable-next-line import/prefer-default-export
export const obtenerTickets = async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };

  const tickets = await axios.get(`${global.domain}/tickets/`, config);
  return tickets;
}

export const editarTicket = (ticket) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };

  const tickets = await axios.put(`${global.domain}/tickets/`, ticket, config);
  return tickets;
}

export const crearTicket = (ticket) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };

  const tickets = await axios.post(`${global.domain}/tickets/`, ticket, config);
  return tickets;
}

export const eliminarTicket = (ticket) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  
  const tickets = await axios.delete(`${global.domain}/tickets/${ticket.id}`, config);
  return tickets;
}

