const actions = {
  SET_LOAD_TYPE: 'solicitudes/SET_LOAD_TYPE',
  SET_TIPO: 'solicitudes/SET_TIPO',
  SET_SOL_DATA: 'solicitudes/SET_SOL_DATA',
  SET_SOL_PROPS: 'solicitudes/SET_SOL_PROPS',
  SET_SOL_EMPLOYEES: 'solicitudes/SET_SOL_EMPLOYEES',
  SET_SOL_NUMBER: 'solicitudes/SET_SOL_NUMBER',
  SET_DISABLE_NEXT: 'solicitudes/SET_DISABLE_NEXT',

  SET_QUERY: 'solicitudes/SET_QUERY',
  SET_STEP: 'solicitudes/SET_STEP',
  SET_STATE: 'solicitudes/SET_STATE',
  /** Solicitudes Alta */
  LOAD_SOLICITUDES_ALTA: 'solicitudes/LOAD_SOLICITUDES_ALTA',
  LOAD_SOLICITUDES_ALTA_FINISH: 'solicitudes/LOAD_SOLICITUDES_ALTA_FINISH',
  EDITAR_SOLICITUD_ALTA: 'solicitudes/EDITAR_SOLICITUD_ALTA',
  CREAR_SOLICITUD_ALTA: 'solicitudes/CREAR_SOLICITUD_ALTA',
  ELIMINAR_SOLICITUD_ALTA: 'solicitudes/ELIMINAR_SOLICITUD_ALTA',
  /** Solicitudes Baja */
  LOAD_SOLICITUDES_BAJA: 'solicitudes/LOAD_SOLICITUDES_BAJA',
  LOAD_SOLICITUDES_BAJA_FINISH: 'solicitudes/LOAD_SOLICITUDES_BAJA_FINISH',
  EDITAR_SOLICITUD_BAJA: 'solicitudes/EDITAR_SOLICITUD_BAJA',
  CREAR_SOLICITUD_BAJA: 'solicitudes/CREAR_SOLICITUD_BAJA',
  ELIMINAR_SOLICITUD_BAJA: 'solicitudes/ELIMINAR_SOLICITUD_BAJA',
  /** Solicitudes CARTA_ACLARATORIA */
  LOAD_SOLICITUDES_CARTA_ACLARATORIA: 'solicitudes/LOAD_SOLICITUDES_CARTA_ACLARATORIA',
  LOAD_SOLICITUDES_CARTA_ACLARATORIA_FINISH:
    'solicitudes/LOAD_SOLICITUDES_CARTA_ACLARATORIA_FINISH',
  EDITAR_SOLICITUD_CARTA_ACLARATORIA: 'solicitudes/EDITAR_SOLICITUD_CARTA_ACLARATORIA',
  CREAR_SOLICITUD_CARTA_ACLARATORIA: 'solicitudes/CREAR_SOLICITUD_CARTA_ACLARATORIA',
  ELIMINAR_SOLICITUD_CARTA_ACLARATORIA: 'solicitudes/ELIMINAR_SOLICITUD_CARTA_ACLARATORIA',
  /** Solicitudes CARTA_PATRONAL */
  LOAD_SOLICITUDES_CARTA_PATRONAL: 'solicitudes/LOAD_SOLICITUDES_CARTA_PATRONAL',
  LOAD_SOLICITUDES_CARTA_PATRONAL_FINISH: 'solicitudes/LOAD_SOLICITUDES_CARTA_PATRONAL_FINISH',
  EDITAR_SOLICITUD_CARTA_PATRONAL: 'solicitudes/EDITAR_SOLICITUD_CARTA_PATRONAL',
  CREAR_SOLICITUD_CARTA_PATRONAL: 'solicitudes/CREAR_SOLICITUD_CARTA_PATRONAL',
  ELIMINAR_SOLICITUD_CARTA_PATRONAL: 'solicitudes/ELIMINAR_SOLICITUD_CARTA_PATRONAL',
  /** Solicitudes CONSTANCIA_LABORAL */
  LOAD_SOLICITUDES_CONSTANCIA_LABORAL: 'solicitudes/LOAD_SOLICITUDES_CONSTANCIA_LABORAL',
  LOAD_SOLICITUDES_CONSTANCIA_LABORAL_FINISH:
    'solicitudes/LOAD_SOLICITUDES_CONSTANCIA_LABORAL_FINISH',
  EDITAR_SOLICITUD_CONSTANCIA_LABORAL: 'solicitudes/EDITAR_SOLICITUD_CONSTANCIA_LABORAL',
  CREAR_SOLICITUD_CONSTANCIA_LABORAL: 'solicitudes/CREAR_SOLICITUD_CONSTANCIA_LABORAL',
  ELIMINAR_SOLICITUD_CONSTANCIA_LABORAL: 'solicitudes/ELIMINAR_SOLICITUD_CONSTANCIA_LABORAL',
  /** Solicitudes CUENTAS_DISPERSION */
  LOAD_SOLICITUDES_CUENTAS_DISPERSION: 'solicitudes/LOAD_SOLICITUDES_CUENTAS_DISPERSION',
  LOAD_SOLICITUDES_CUENTAS_DISPERSION_FINISH:
    'solicitudes/LOAD_SOLICITUDES_CUENTAS_DISPERSION_FINISH',
  EDITAR_SOLICITUD_CUENTAS_DISPERSION: 'solicitudes/EDITAR_SOLICITUD_CUENTAS_DISPERSION',
  CREAR_SOLICITUD_CUENTAS_DISPERSION: 'solicitudes/CREAR_SOLICITUD_CUENTAS_DISPERSION',
  ELIMINAR_SOLICITUD_CUENTAS_DISPERSION: 'solicitudes/ELIMINAR_SOLICITUD_CUENTAS_DISPERSION',
  /** Solicitudes FONACOT */
  LOAD_SOLICITUDES_FONACOT: 'solicitudes/LOAD_SOLICITUDES_FONACOT',
  LOAD_SOLICITUDES_FONACOT_FINISH: 'solicitudes/LOAD_SOLICITUDES_FONACOT_FINISH',
  EDITAR_SOLICITUD_FONACOT: 'solicitudes/EDITAR_SOLICITUD_FONACOT',
  CREAR_SOLICITUD_FONACOT: 'solicitudes/CREAR_SOLICITUD_FONACOT',
  ELIMINAR_SOLICITUD_FONACOT: 'solicitudes/ELIMINAR_SOLICITUD_FONACOT',
  /** Solicitudes GUARDERIA */
  LOAD_SOLICITUDES_GUARDERIA: 'solicitudes/LOAD_SOLICITUDES_GUARDERIA',
  LOAD_SOLICITUDES_GUARDERIA_FINISH: 'solicitudes/LOAD_SOLICITUDES_GUARDERIA_FINISH',
  EDITAR_SOLICITUD_GUARDERIA: 'solicitudes/EDITAR_SOLICITUD_GUARDERIA',
  CREAR_SOLICITUD_GUARDERIA: 'solicitudes/CREAR_SOLICITUD_GUARDERIA',
  ELIMINAR_SOLICITUD_GUARDERIA: 'solicitudes/ELIMINAR_SOLICITUD_GUARDERIA',
  /** Solicitudes INCAPACIDAD */
  LOAD_SOLICITUDES_INCAPACIDAD: 'solicitudes/LOAD_SOLICITUDES_INCAPACIDAD',
  LOAD_SOLICITUDES_INCAPACIDAD_FINISH: 'solicitudes/LOAD_SOLICITUDES_INCAPACIDAD_FINISH',
  EDITAR_SOLICITUD_INCAPACIDAD: 'solicitudes/EDITAR_SOLICITUD_INCAPACIDAD',
  CREAR_SOLICITUD_INCAPACIDAD: 'solicitudes/CREAR_SOLICITUD_INCAPACIDAD',
  ELIMINAR_SOLICITUD_INCAPACIDAD: 'solicitudes/ELIMINAR_SOLICITUD_INCAPACIDAD',
  /** Solicitudes INFONAVIT */
  LOAD_SOLICITUDES_INFONAVIT: 'solicitudes/LOAD_SOLICITUDES_INFONAVIT',
  LOAD_SOLICITUDES_INFONAVIT_FINISH: 'solicitudes/LOAD_SOLICITUDES_INFONAVIT_FINISH',
  EDITAR_SOLICITUD_INFONAVIT: 'solicitudes/EDITAR_SOLICITUD_INFONAVIT',
  CREAR_SOLICITUD_INFONAVIT: 'solicitudes/CREAR_SOLICITUD_INFONAVIT',
  ELIMINAR_SOLICITUD_INFONAVIT: 'solicitudes/ELIMINAR_SOLICITUD_INFONAVIT',
  /** Solicitudes MIGRACION */
  LOAD_SOLICITUDES_MIGRACION: 'solicitudes/LOAD_SOLICITUDES_MIGRACION',
  LOAD_SOLICITUDES_MIGRACION_FINISH: 'solicitudes/LOAD_SOLICITUDES_MIGRACION_FINISH',
  EDITAR_SOLICITUD_MIGRACION: 'solicitudes/EDITAR_SOLICITUD_MIGRACION',
  CREAR_SOLICITUD_MIGRACION: 'solicitudes/CREAR_SOLICITUD_MIGRACION',
  ELIMINAR_SOLICITUD_MIGRACION: 'solicitudes/ELIMINAR_SOLICITUD_MIGRACION',
  /** Solicitudes MOD_DATOS */
  LOAD_SOLICITUDES_MOD_DATOS: 'solicitudes/LOAD_SOLICITUDES_MOD_DATOS',
  LOAD_SOLICITUDES_MOD_DATOS_FINISH: 'solicitudes/LOAD_SOLICITUDES_MOD_DATOS_FINISH',
  EDITAR_SOLICITUD_MOD_DATOS: 'solicitudes/EDITAR_SOLICITUD_MOD_DATOS',
  CREAR_SOLICITUD_MOD_DATOS: 'solicitudes/CREAR_SOLICITUD_MOD_DATOS',
  ELIMINAR_SOLICITUD_MOD_DATOS: 'solicitudes/ELIMINAR_SOLICITUD_MOD_DATOS',
  /** Solicitudes MOD_SALARIO */
  LOAD_SOLICITUDES_MOD_SALARIO: 'solicitudes/LOAD_SOLICITUDES_MOD_SALARIO',
  LOAD_SOLICITUDES_MOD_SALARIO_FINISH: 'solicitudes/LOAD_SOLICITUDES_MOD_SALARIO_FINISH',
  EDITAR_SOLICITUD_MOD_SALARIO: 'solicitudes/EDITAR_SOLICITUD_MOD_SALARIO',
  CREAR_SOLICITUD_MOD_SALARIO: 'solicitudes/CREAR_SOLICITUD_MOD_SALARIO',
  ELIMINAR_SOLICITUD_MOD_SALARIO: 'solicitudes/ELIMINAR_SOLICITUD_MOD_SALARIO',
  /** Solicitudes MOD_NOMINA */
  LOAD_SOLICITUDES_MOD_NOMINA: 'solicitudes/LOAD_SOLICITUDES_MOD_NOMINA',
  LOAD_SOLICITUDES_MOD_NOMINA_FINISH: 'solicitudes/LOAD_SOLICITUDES_MOD_NOMINA_FINISH',
  EDITAR_SOLICITUD_MOD_NOMINA: 'solicitudes/EDITAR_SOLICITUD_MOD_NOMINA',
  CREAR_SOLICITUD_MOD_NOMINA: 'solicitudes/CREAR_SOLICITUD_MOD_NOMINA',
  ELIMINAR_SOLICITUD_MOD_NOMINA: 'solicitudes/ELIMINAR_SOLICITUD_MOD_NOMINA',
  /** Solicitudes ST7 */
  LOAD_SOLICITUDES_ST7: 'solicitudes/LOAD_SOLICITUDES_ST7',
  LOAD_SOLICITUDES_ST7_FINISH: 'solicitudes/LOAD_SOLICITUDES_ST7_FINISH',
  EDITAR_SOLICITUD_ST7: 'solicitudes/EDITAR_SOLICITUD_ST7',
  CREAR_SOLICITUD_ST7: 'solicitudes/CREAR_SOLICITUD_ST7',
  ELIMINAR_SOLICITUD_ST7: 'solicitudes/ELIMINAR_SOLICITUD_ST7',
  /** Solicitudes TRANSFERENCIA_SEMANAS */
  LOAD_SOLICITUDES_TRANSFERENCIA_SEMANAS: 'solicitudes/LOAD_SOLICITUDES_TRANSFERENCIA_SEMANAS',
  LOAD_SOLICITUDES_TRANSFERENCIA_SEMANAS_FINISH:
    'solicitudes/LOAD_SOLICITUDES_TRANSFERENCIA_SEMANAS_FINISH',
  EDITAR_SOLICITUD_TRANSFERENCIA_SEMANAS: 'solicitudes/EDITAR_SOLICITUD_TRANSFERENCIA_SEMANAS',
  CREAR_SOLICITUD_TRANSFERENCIA_SEMANAS: 'solicitudes/CREAR_SOLICITUD_TRANSFERENCIA_SEMANAS',
  ELIMINAR_SOLICITUD_TRANSFERENCIA_SEMANAS: 'solicitudes/ELIMINAR_SOLICITUD_TRANSFERENCIA_SEMANAS',
  /** Solicitudes VISA */
  LOAD_SOLICITUDES_VISA: 'solicitudes/LOAD_SOLICITUDES_VISA',
  LOAD_SOLICITUDES_VISA_FINISH: 'solicitudes/LOAD_SOLICITUDES_VISA_FINISH',
  EDITAR_SOLICITUD_VISA: 'solicitudes/EDITAR_SOLICITUD_VISA',
  CREAR_SOLICITUD_VISA: 'solicitudes/CREAR_SOLICITUD_VISA',
  ELIMINAR_SOLICITUD_VISA: 'solicitudes/ELIMINAR_SOLICITUD_VISA',
  /** Solicitudes RENOVACION */
  LOAD_SOLICITUDES_RENOVACION: 'solicitudes/LOAD_SOLICITUDES_RENOVACION',
  LOAD_SOLICITUDES_RENOVACION_FINISH: 'solicitudes/LOAD_SOLICITUDES_RENOVACION_FINISH',
  EDITAR_SOLICITUD_RENOVACION: 'solicitudes/EDITAR_SOLICITUD_RENOVACION',
  CREAR_SOLICITUD_RENOVACION: 'solicitudes/CREAR_SOLICITUD_RENOVACION',
  ELIMINAR_SOLICITUD_RENOVACION: 'solicitudes/ELIMINAR_SOLICITUD_RENOVACION',
}

export default actions
