import React from 'react'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Avatar } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './style.module.scss'

@connect(({ user }) => ({ user }))
class ProfileMenu extends React.Component {

  logout = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  render() {
    const { user } = this.props
    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <strong>
            Hola, {user.nombre || 'Anonymous'}
          </strong>
          <div>
            <strong>
              Rol:{' '}
            </strong>
            {user.rol}
            <br />
            <strong>
              Puesto:{' '}
            </strong>
            {user.puesto}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div>
            <strong>
              Usuario:{' '}
            </strong>
            {user.username}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="javascript: void(0);" onClick={this.logout}>
            <i className={`${styles.menuIcon} icmn-exit`} />
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={['click']} onVisibleChange={this.addCount}>
        <div className={styles.dropdown}>
          <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
        </div>
      </Dropdown>
    );
  }
}

export default ProfileMenu
