/* eslint-disable no-debugger */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { obtenerBancos, editarBanco, crearBanco, eliminarBanco } from 'services/bancos'
import actions from './actions'

export function* LOAD_BANCOS() {
  yield put({
    type: 'bancos/SET_STATE',
    payload: {
      loading: true,
    },
  })

  try {

    const response = yield call(obtenerBancos)

    if (response) {
      const { data: entities } = response
      yield put({
        type: 'bancos/SET_STATE',
        payload: {
          loading: false,
          entities
        },
      })

      // yield put({
      //   type: 'user/SET_STATE',
      //   payload: {
      //     loading: false,
      //   },
      // })
    }
  } catch (error) {
    const token = localStorage.getItem('token');
    if(token){
      notification.warning({
        message: "Hubo un error al obtener los bancos",
        description: "Verifica que estés conectado a internet",
      });
    }else{
      console.error(error)
    }
  }
}

export function* EDITAR_BANCO(banco) {

  try {

    const response = yield call(editarBanco(banco.payload));

    if (response) {
      const { data: entities } = response
      yield put({
        type: 'bancos/SET_STATE',
        payload: {
          loading: false,
          entities
        },
      })

    }
  } catch (error) {
    notification.warning({
      message: "Hubo un error al editar el elemento",
      description: "Verifica que estés conectado a internet",
    });

  }
}

export function* CREAR_BANCO(banco) {

  try {

    const response = yield call(crearBanco(banco.payload));

    if (response) {
      const { data: entities } = response
      yield put({
        type: 'bancos/SET_STATE',
        payload: {
          loading: false,
          entities
        },
      })

    }
  } catch (error) {
    notification.warning({
      message: "Hubo un error al crear el elemento",
      description: "Verifica que estés conectado a internet",
    });

  }
}

export function* ELIMINAR_BANCO(banco) {

  try {

    const response = yield call(eliminarBanco(banco.payload));

    if (response) {
      const { data: entities } = response
      yield put({
        type: 'bancos/SET_STATE',
        payload: {
          loading: false,
          entities
        },
      })

    }
  } catch (error) {
    notification.warning({
      message: "Hubo un error al eliminar el elemento",
      description: "Verifica que estés conectado a internet",
    });

  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_BANCOS, LOAD_BANCOS),
    takeEvery(actions.EDITAR_BANCO, EDITAR_BANCO),
    takeEvery(actions.CREAR_BANCO, CREAR_BANCO),
    takeEvery(actions.ELIMINAR_BANCO, ELIMINAR_BANCO),
    LOAD_BANCOS(), // run once on app load to check user auth
  ])
}
