import React from 'react'
// import HomeMenu from './HomeMenu'
// import IssuesHistory from './IssuesHistory'
// import LiveSearch from './LiveSearch'
import { Helmet } from 'react-helmet'
import { Typography } from 'antd'
import ProfileMenu from './ProfileMenu'
// import LanguageSelector from './LanguageSelector'
import styles from './style.module.scss'

const { Title } = Typography

function TopBar() {

  const [title, setTitle] = React.useState("")

  return (
    <div className={styles.topbar}>
      <Helmet
        onChangeClientState={({ title: newTitle }) => {
          setTitle(newTitle);
        }}
      />
      <Title style={{ margin: 0 }} level={4}>{title}</Title>
      <div className="ml-auto" />
      <ProfileMenu />
    </div>
  )
}

export default TopBar
