import axios from 'axios'
// import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const obtenerDashboard = (date) => async () => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const dashboard = await axios.get(`${global.domain}/nominas/dashboard/${date}/`,config);
  return dashboard;
}

