import actions from './actions'

const initialState = {
  entities: [],
  expandedRowKeys: [],
  employeeDetails: {},
  index: {},
  loading: false
};

export default function finiquitosReducer(state = initialState, action) {
  let index;
  switch (action.type) {
    case actions.SET_STATE:
      if (action.payload.entities) {
        index = action.payload.entities.reduce((ac, ent) => {
          return { ...ac, [ent.id]: ent };
        }, {});
      }

      return {
        ...state,
        ...action.payload,
        index
      }
    case actions.TOGGLE_EXPANDED_ROW_KEYS: {
      let expandedRowKeys = [ ...state.expandedRowKeys ]
      if (state.expandedRowKeys.includes(action.payload)) {
        expandedRowKeys = expandedRowKeys.filter((rowId) => {
          return rowId !== action.payload
        })
      } else {
        expandedRowKeys = [...expandedRowKeys, action.payload]
      }
      return {
        ...state,
        expandedRowKeys
      }
    }
    case actions.SET_EMPLOYEE_DETAILS:
      return {
        ...state,
        employeeDetails: {
          ...state.employeeDetails,
          ...action.payload
        }
      }
    default:
      return state
  }
}
